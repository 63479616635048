import React, { useState, useContext, useEffect } from "react";
import {
  Grid, Paper, Button, Typography, Select, MenuItem, FormControl, InputLabel, TextField, LinearProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import InvoicesTable from '../../components/invoices/InvoicesTable';
import { LocalizationContext } from '../../context/localizationContext';
import { AuthContext } from '../../context/authContext';
import { KeyboardDatePicker, } from "@material-ui/pickers";
import APICall from '../../functional/APIRequests';
import { useSnackbar } from 'notistack';
import moment from 'moment';
const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  divider: {
    width: "80%"
  },
  cartButton: {
    width: '100%'
  }
}));

const Invoices = props => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { getTranslation } = useContext(LocalizationContext);
  const { setLoading, loading } = useContext(AuthContext);
  document.title = getTranslation('invoices_list_browser_title') + ' | Prodiagnostics';
  const [methods, setMethods] = useState([])
  const [clients, setClients] = useState([])
  const [invoices, setInvoices] = useState([])
  const [selectedInv, setSelectedInv] = useState([])
  const [allChecked, setAllChecked] = useState(false);
  const [totals, setTotals] = useState({})
  const [data, setData] = useState({
    dateFrom: moment().subtract(1, 'months').startOf('month'),
    dateTo: moment().subtract(1, 'months').endOf('month'),
    invoiceDate: null,
    invoiceDueDate: null,
    client: null,
    analysis: null,
  })
  const handleDateChange = (value, field) => {
    setData({ ...data, [field]: value });
  }
  const handleSelectChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  const handleChange = () => {
    // setData({ ...data, [input]: e });
  }
  const fetchClients = () => {
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `prodiagnostics/1/customers?filter=&sort&offset=0&limit=9999999`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setClients(res.data.filter(item => item.sys_id !== '1' && item.sys_id !== '2'));
    })
  }
  const fetchMethods = () => {
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products/methods`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setMethods(res.data);
    })
  }

  React.useEffect(() => {
    fetchClients()
    fetchMethods()
    // eslint-disable-next-line
  }, [])
  const fetchInvoices = (() => {
    setLoading(true);
    let url = `prodiagnostics/1/invoice?intervall[start]=${moment(data.dateFrom).startOf("day").unix()}&intervall[end]=${moment(data.dateTo).endOf("day").unix()}`;
    if (data.client) { url += '&customer_id=' + data.client }
    if (data.analysis) { url += '&test_method_id=' + data.analysis }
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: url,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      let array = [];
      if (res.data) {
        let keys = Object.keys(res.data.customers);
        keys.forEach(key =>
          array.push({
            sys_id: res.data.customers[key].organisation_id,
            customer_number: res.data.customers[key].customer_number,
            name: res.data.customers[key].name,
            nr_analysis: String(res.data.customers[key].nr_analysis),
            nr_referrals: String(res.data.customers[key].nr_referrals),
            check: false,
          }))
        setTotals({
          customers_to_invoice: res.data.customers_to_invoice,
          referrals_to_invoice: res.data.referrals_to_invoice,
          analysis_to_invoice: res.data.analysis_to_invoice,
          total_referrals: res.data.total_referrals,
          total_analysis: res.data.total_analysis,
          referrals_to_invoice_in_slasken: res.data.referrals_to_invoice_in_slasken,
          analysis_to_invoice_in_slasken: res.data.analysis_to_invoice_in_slasken,
        })
      }
      setInvoices(array);
      setLoading(false)
    })
  })
  const handleSelectLine = (id) => {
    let index = invoices.findIndex(item => item.sys_id === id);
    let inv = JSON.parse(JSON.stringify(invoices));
    inv[index].check = !inv[index].check;
    let count = inv.filter(item => item.check === true);
    setInvoices(inv);
    setAllChecked(count.length === inv.length);
  }
  const sendToFortnox = (() => {
    setLoading(true);
    var form = new FormData();
    form.append("intervall[start]", moment(data.dateFrom).unix());
    form.append("intervall[end]", moment(data.dateTo).unix());
    form.append("invoice_date", moment(data.invoiceDate).unix());
    form.append("invoice_due_date", moment(data.invoiceDueDate).unix());
    if (data.analysis) { form.append("test_method_id", data.analysis) }
    selectedInv.forEach(item => {
      form.append("customer_ids[]", item);
    })
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/invoice/fortnox-create`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      fetchInvoices();
      setLoading(false);
    })
  })
  const handleSelectAll = () => {
    setInvoices(invoices.map(item => { return { ...item, check: !allChecked } }))
    setAllChecked(!allChecked)
  }
  useEffect(() => {
    setSelectedInv(invoices.filter(item => !!item.check).map(item => item.sys_id))
  }, [invoices])
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={0}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {getTranslation('invoices_page_title')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {getTranslation('invoices_page_subtitle')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4} md={4}>
                  <KeyboardDatePicker
                    className='fixSelectAndDates'
                    format="YYYY-MM-DD"
                    autoOk
                    InputAdornmentProps={{ position: "start" }}
                    label={getTranslation('invoices_filter_invoice_date')}
                    error={!data.invoiceDate}
                    value={data.invoiceDate || null}
                    onChange={(e) => handleDateChange(e, 'invoiceDate')} />
                </Grid>
                <Grid item xs={4} md={4}>
                  <KeyboardDatePicker
                    className='fixSelectAndDates'
                    format="YYYY-MM-DD"
                    autoOk
                    InputAdornmentProps={{ position: "start" }}
                    label={getTranslation('invoices_filter_invoice_due_date')}
                    error={!data.invoiceDueDate}
                    value={data.invoiceDueDate || null}
                    onChange={(e) => handleDateChange(e, 'invoiceDueDate')} />
                </Grid>
                <Grid item xs={4} md={4} style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Button
                    // style={{ margin: '0px 5px', float: 'right' }}
                    variant="contained"
                    color="primary"
                    disabled={selectedInv.length === 0 || !data.invoiceDueDate || !data.invoiceDate}
                    onClick={sendToFortnox}
                  // startIcon={<ArrowBackIos style={{ height: 12, width: 12 }} />}
                  >{getTranslation('invoice_submit_to_fortnox_button')}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {loading && <LinearProgress />}
        <Paper className={classes.paperTitle} elevation={0}>
          <Grid container style={{ marginBottom: 30 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                {getTranslation('invoices_page_filter_title')}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {getTranslation('invoices_page_filter_subtitle')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 30 }}>
            <Grid item xs={12} md={10}>
              <Grid container>
                <Grid item xs={6} md={3}>
                  <KeyboardDatePicker
                    className='fixSelectAndDates'
                    format="YYYY-MM-DD"
                    autoOk
                    InputAdornmentProps={{ position: "start" }}
                    label={getTranslation('invoices_filter_from_date')}
                    error={!data.dateFrom}
                    value={data.dateFrom || null}
                    onChange={(e) => handleDateChange(e, 'dateFrom')} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <KeyboardDatePicker
                    className='fixSelectAndDates'
                    format="YYYY-MM-DD"
                    autoOk
                    InputAdornmentProps={{ position: "start" }}
                    label={getTranslation('invoices_filter_to_date')}
                    error={!data.dateTo}
                    value={data.dateTo || null}
                    onChange={(e) => handleDateChange(e, 'dateTo')} />
                </Grid>
                <Grid item xs={6} md={3}>

                  <Autocomplete
                    options={clients}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {

                      setData({ ...data, client: !newValue ? newValue : newValue.sys_id });
                    }}
                    renderInput={(params) => <TextField {...params} label={getTranslation('invoices_filter_select_client')} variant="filled" />}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl className={`fixSelectAndDates ${classes.formControl}`}>
                    <InputLabel>{getTranslation('invoices_filter_select_analysis')}</InputLabel>
                    <Select
                      value={data.analysis || ''}
                      name='analysis'
                      onChange={handleSelectChange}
                    >
                      {methods &&
                        methods.map(item => {
                          return <MenuItem value={item.sys_id} key={item.sys_id}>{item.name}</MenuItem>
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={2} style={{ padding: '10px' }}>
              <Button
                disabled={!data.dateFrom || !data.dateTo}
                // style={{ height: '100%' }}
                variant="contained"
                color="primary"
                onClick={fetchInvoices}
              >{getTranslation('generic_show_button')}</Button>
            </Grid>
          </Grid>
        </Paper>

        {invoices.length > 0 &&
          <Paper className={classes.paperTitle} elevation={0}>
            <Grid container style={{ marginBottom: 30 }}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_total_referrals')}</b> {totals.total_referrals}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_total_analysis')}</b> {totals.total_analysis}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_customers_to_invoice')}</b> {totals.customers_to_invoice}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_referrals_to_invoice')}</b> {totals.referrals_to_invoice}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_analysis_to_invoice')}</b> {totals.analysis_to_invoice}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_referrals_to_invoice_in_slasken')}</b> {totals.referrals_to_invoice_in_slasken}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <b>{getTranslation('invoices_totals_analysis_to_invoice_in_slasken')}</b> {totals.analysis_to_invoice_in_slasken}
                </Typography>
              </Grid>
            </Grid>
            {false &&
              <Grid container>
                <Grid item xs={3}>
                  <TextField
                    variant="filled"
                    label={getTranslation('invoices_save_filter')}
                    name='testLotNr'
                    // disabled={loading}
                    value={data.testLotNr}
                    onChange={handleChange} />
                </Grid>
                <Grid item xs={6} md={2} style={{ padding: '10px' }}>
                  <Button
                    style={{ height: '100%' }}
                    variant="contained"
                    color="primary"
                    onClick={fetchInvoices}
                  >{getTranslation('generic_button_save')}</Button>
                </Grid>
              </Grid>
            }
            <InvoicesTable {...props} data={invoices} handleSelectLine={handleSelectLine} handleSelectAll={handleSelectAll} allChecked={allChecked} />
          </Paper>
        }
      </Grid>
    </Grid >
  );
};






export default Invoices;
