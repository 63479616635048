import React, {useContext, useEffect, useState} from "react";
import {Button, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AnalysesTable from "../../components/analysis/AnalysesTable";
import {LocalizationContext} from '../../context/localizationContext';
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import APICall from "../../functional/APIRequests";
import {AuthContext} from "../../context/authContext";
import {useSnackbar} from "notistack";
import {OrgContext} from "../../context/orgContext";
import moment from "moment";
import WorkPlaceTable from "../../components/analysis/WorkplaceAnalysesTable";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  unselectedTab: {
    borderRadius: 0,
    borderWidth: "1px !important",
    borderStyle: "solid !important",
    borderColor: "#8c8c8c !important",
    borderBottom: "none !important",
    color: "#1a1a1a"
  },
  tableWrapper: {
    border: "1px solid #8c8c8c",
    padding: "20px 10px",
  },
  tableWrapperWithTabs: {
    border: "1px solid #8c8c8c",
    padding: "20px 10px",
    borderTopWidth: 2,
    borderTopColor: "#E41937",
    marginTop: "-1px"
  }
}));
const Analyses = props => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const {getTranslation} = useContext(LocalizationContext);
  const {setLoading, user} = useContext(AuthContext);
  const {selectedOrg} = useContext(OrgContext);

  document.title = getTranslation('analysis_list_browser_title') + ' | Prodiagnostics';

  const [selectedTab, setSelectedTab] = React.useState(
    !!sessionStorage.getItem("aTTab") ? Number(JSON.parse(sessionStorage.getItem("aTTab"))) : 1
  ); // 1-Pro / 2-Workplace

  useEffect(() => {
    if (selectedOrg.pro === "1" && selectedOrg.workplace === "0") {
      setSelectedTab(1);
      sessionStorage.setItem("aTTab", JSON.stringify(1));
    } else if ((selectedOrg.workplace === "1" && !selectedOrg.mro && selectedOrg.pro === "0") || selectedOrg.mro) {
      setSelectedTab(2);
      sessionStorage.setItem("aTTab", JSON.stringify(2));
    } else {
      setSelectedTab(1);
      sessionStorage.setItem("aTTab", JSON.stringify(1));
    }
  }, [])

  const [count, setCount] = useState(
    !!sessionStorage.getItem("aLCount")
      ? Number(JSON.parse(sessionStorage.getItem("aLCount")))
      : 0
  );
  const [page, setPage] = useState(
    !!sessionStorage.getItem("aLPage")
      ? Number(JSON.parse(sessionStorage.getItem("aLPage")))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    !!sessionStorage.getItem("aLRowsPP")
      ? Number(JSON.parse(sessionStorage.getItem("aLRowsPP")))
      : 20
  );
  const [sort, setSort] = useState(
    !!sessionStorage.getItem("aLSort")
      ? JSON.parse(sessionStorage.getItem("aLSort"))
      : {
        column: "referral.result_date",
        by: "desc",
      }
  );
  const [filter, setFilter] = useState(
    !!sessionStorage.getItem("aLFilter")
      ? JSON.parse(sessionStorage.getItem("aLFilter"))
      : false
  );
  const [selectedHandledFilters, setSelectedHandleFilters] = useState({
    handled: 0,
    archived: 0,
  });
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [methods, setMethods] = useState([]);
  const [status, setStatus] = useState([]);
  const [checkedReferrals, setCheckedReferrals] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const generetaQueryString = (exportFile = false) => {
    let queryString = `?sort=${sort.column} ${sort.by}`;
    if(!exportFile) {
      queryString += `&limit=${rowsPerPage}&offset=${rowsPerPage * page}`;
    }
    queryString += `&filter[workplace]=0`
    if (filter) {
      Object.keys(filter).forEach((f) => {
        if(f === "referral.order_created") {
          queryString += `&filter[order_created]=${filter[f]}`;
        }
        else if (f !== "referral.is_positive" && filter[f] !== "all") {
          queryString += `&filter[${f}]=${filter[f]}`;
        }
      });
    }
    return queryString;
  };

  const fetchData = () => {
    let url = `customer/${selectedOrg.id}/referrals${generetaQueryString()}`;

    if(selectedHandledFilters.archived !== null && selectedHandledFilters.handled !== null ){
        if (selectedHandledFilters.archived === 1) {
          url += `&filter[archived]=1`;
        } else {
          if (selectedHandledFilters.handled === 1) {
            url += `&filter[handled]=1`;
          } else {
            url += `&filter[handled]=${selectedHandledFilters.handled}&filter[archived]=0`;
          }
        }
    }

    setLoading(true);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: url,
      verb: "get",
      server: "api",
    }).then((res) => {
      setLoading(false);
      if (res) {
        if (res.total_records) {
          setCount(Number(res.total_records) || 0);
        }

        if ((!res.data || res.data.length === 0) && page > 0) {
          setPage(0);
        }
        if (
          selectedHandledFilters.handled === 0 &&
          selectedHandledFilters.archived !== 1
        )
          setTotalRecords(res.total_records);
        // let nData = Object.keys(res.data).map(key => { return res.data[key] });
        if (res.data) {
          setData(
            res.data.map((data) => {
              let analysis = [];
              if (Array.isArray(data.analysis)) {
                analysis = data.analysis;
              } else {
                Object.keys(data.analysis).forEach((key) => {
                  analysis.push(data.analysis[key]);
                });
              }
              data.referral.test_date =
                data.referral.test_date === "0" || !data.referral.test_date
                  ? "-"
                  : moment.unix(data.referral.test_date).format("YYYY-MM-DD");
              data.referral.registration_date =
                data.referral.registration_date === "0" ||
                !data.referral.registration_date
                  ? "-"
                  : moment
                    .unix(data.referral.registration_date)
                    .format("YYYY-MM-DD");
              data.referral.arrival_date =
                data.referral.arrival_date === "0" ||
                !data.referral.arrival_date
                  ? "-"
                  : moment
                    .unix(data.referral.arrival_date)
                    .format("YYYY-MM-DD");
              data.referral.result_date =
                data.referral.result_date === "0" || !data.referral.result_date
                  ? "-"
                  : moment.unix(data.referral.result_date).format("YYYY-MM-DD");
              data.referral.test_method_id = (
                methods.find(
                  (st) => st.id === data.referral.test_method_id
                ) || {text: ""}
              ).text;
              data.referral.status_id = {
                color:
                  data.referral.status_id === "1"
                    ? "#FFEE00"
                    : data.referral.status_id === "2"
                      ? "#EFA700"
                      : "#28A745",
                name: status.find((st) => st.id === data.referral.status_id)
                  .text,
                id: data.referral.status_id
              };
              data.referral.analysis =
                analysis.length === 0 ? false : analysis.length;
              data.referral.archived_by = data.referral.archived_by;
              data.referral.archived_at = data.referral.archived_at;
              data.referral.handled_by = data.referral.handled_by;
              data.referral.handled_at = data.referral.handled_at;
              data.user_archived.name = data.user_archived.name;
              data.user_handled.name = data.user_handled.name;
              data.organisation.id = data.organisation.sys_id;
              return {
                ...data,
              };
            })
          );
        } else setData([]);
      }
    });
  };

  const fetchClients = () => {
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `prodiagnostics/1/customers?filter=&sort&offset=0&limit=99999999`,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res && res.data)
        setClients(
          res.data.filter((item) => item.sys_id !== "1" && item.sys_id !== "2")
        );
    });
  };

  const reassignReferral = () => {
    let referral = data.find(r => r.referral.referral_id === checkedReferrals[0]);

    let formData = new FormData();
    formData.append("organisation_id", selectedCustomer);
    formData.append("user_id", user.id);

    for (let i in checkedReferrals) {
      formData.append(`referrals[${i}]`, checkedReferrals[i]);
    }

    setLoading(true);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/${referral.referral.sys_id}/reassign`,
      verb: "post",
      server: "api",
      formData: true,
      data: formData
    }).then((res) => {
      setLoading(false);
      if (res && res.data) {
        enqueueSnackbar(getTranslation("generic_submited_success"), {
          variant: "success",
        });
        fetchData();
        setSelectedCustomer("");
        setCheckedReferrals([]);
      }
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
  });

  useEffect(() => {
    if (selectedOrg.id === "1" && !selectedOrg.mro) {
      fetchClients();
    }
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={0}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {selectedOrg.mro ? getTranslation('analysis_page_mro_title') : getTranslation('analysis_page_title')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {selectedOrg.mro ? getTranslation('analysis_page_mro_subtitle') : getTranslation('analysis_page_subtitle')}
              </Typography>
            </Grid>
            {checkedReferrals.length > 0 &&
            <Grid item xs={12} md={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Autocomplete
                    options={clients}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setSelectedCustomer(!newValue ? newValue : newValue.sys_id);
                    }}
                    renderOption={(selected) => (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography variant="body2">{selected.customer_number}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">{selected.name}</Typography>
                        </Grid>
                      </Grid>
                    )}
                    renderInput={params => <TextField {...params}/>}
                    getOptionLabel={(selected) =>
                      `${selected.customer_number}  ${selected.name}`
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    disabled={!selectedCustomer || selectedCustomer === ""}
                    onClick={reassignReferral}
                  >
                    {getTranslation("analysis_table_reassign_button")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            }
          </Grid>
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          {((selectedOrg.workplace === "1" && selectedOrg.pro === "1" && !selectedOrg.mro) || selectedOrg.id === '1') &&
          <>
            {!selectedOrg.mro &&
            <div>
              <Button
                color="primary"
                size="large"
                style={{marginRight: 5}}
                variant={selectedTab === 1 ? "contained" : "outlined"}
                className={selectedTab === 1 ? classes.selectedTab : classes.unselectedTab}
                onClick={() => {
                  setSelectedTab(1);
                  sessionStorage.setItem("aTTab", JSON.stringify(1));
                }}
              >
                {getTranslation("analyses_pro_button")}
              </Button>
              <Button
                color="primary"
                size="large"
                variant={selectedTab === 2 ? "contained" : "outlined"}
                className={selectedTab === 2 ? classes.selectedTab : classes.unselectedTab}
                onClick={() => {
                  setSelectedTab(2);
                  sessionStorage.setItem("aTTab", JSON.stringify(2));
                }}
              >
                {getTranslation("analyses_workplace_button")}
              </Button>
            </div>
            }
          </>
          }
          <div
            className={selectedOrg.mro ? "" : (selectedOrg.workplace === "1" && selectedOrg.pro === "1") || selectedOrg.id === '1' ? classes.tableWrapperWithTabs : ""}>
            {selectedTab === 1 &&
              <AnalysesTable
                checkedReferrals={checkedReferrals}
                setCheckedReferrals={setCheckedReferrals}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setSort={setSort}
                generetaQueryString={generetaQueryString}
                setFilter={setFilter}
                setSelectedHandleFilters={setSelectedHandleFilters}
                filter={filter}
                sort={sort}
                page={page}
                rowsPerPage={rowsPerPage}
                fetchData={fetchData}
                selectedHandledFilters={selectedHandledFilters}
                data={data}
                methods={methods}
                status={status}
                setTotalRecords={setTotalRecords}
                setStatus={setStatus}
                setMethods={setMethods}
                setCount={setCount}
                count={count}
                totalRecords={totalRecords}
                {...props}
              />
            }
            {selectedTab === 2 && <WorkPlaceTable {...props} />}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Analyses;
