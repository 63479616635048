import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { LocalizationContext } from '../../context/localizationContext';
import moment from 'moment';
import Table from '../Table/index';

const InvoicesTable = props => {
  const { getTranslation, lang } = useContext(LocalizationContext);
  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sort, setSort] = useState({ column: 'customer_number', by: 'asc' })
  const [data, setData] = useState([]);
  // const [nestedId, setNestedId] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortChange = (column, order) => {
    setSort({ column: column, by: order })
  }
  const columns = [
    {
      name: '',
      functionalName: 'check',
      type: 'check',
      filter: false,
      visible: true,
    },
    //Customer number
    {
      name: getTranslation('invoices_list_table_header_customer_number'),
      functionalName: 'customer_number',
      type: 'text',
      filter: false,
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Customer name
    {
      name: getTranslation('invoices_list_table_header_customer'),
      functionalName: 'name',
      type: 'text',
      filter: false,
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Nr referrals
    {
      name: getTranslation('invoices_list_table_header_nr_referrals'),
      functionalName: 'nr_referrals',
      type: 'text',
      filter: false,
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Nr analysis
    {
      name: getTranslation('invoices_list_table_header_nr_analysis'),
      functionalName: 'nr_analysis',
      type: 'text',
      filter: false,
      sortable: true,
      visible: true,
      align: 'left',
    },
  ]
  const sortFunc = (a, b) => {
    let itemA, itemB;
    if (sort.column === 'name') {
      itemA = a[sort.column].toUpperCase();
      itemB = b[sort.column].toUpperCase();
    } else {
      itemA = Number(a[sort.column]);
      itemB = Number(b[sort.column]);
    }

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    if (sort.by === 'asc') {
      return comparison;
    } else {
      return comparison * -1;
    }
  }
  // eslint-disable-next-line
  const fetchData = (e) => {
    let dt = props.data || [];
    let from = page * rowsPerPage;
    let to = ((page + 1) * rowsPerPage) - 1;
    dt = dt.sort(sortFunc);
    setCount(dt.length);
    setData(dt.slice(from, to));
  }
  // eslint-disable-next-line
  useEffect(() => fetchData(), [props.data, sort, page, rowsPerPage])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Table
          hasCheck={true}
          allChecked={props.allChecked}
          handleSelectLine={props.handleSelectLine}
          handleSelectAll={props.handleSelectAll}
          rows={data}
          page={page}
          count={count}
          columns={columns}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortChange={handleSortChange}
          sort={sort}
        />
      </Grid>
    </Grid>
  );
};

export default InvoicesTable;
